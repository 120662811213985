import React from 'react'

const Page404 = () => {
    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Page Under Constructions</h4>
            </div>
        </>
    )
}

export default Page404;
