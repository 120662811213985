import React from 'react'
import { Button, ButtonGroup } from 'reactstrap';
import RangeSlider from 'react-bootstrap-range-slider';
import { BlockPicker } from 'react-color';


export default function DrawingTools({drawingStyle, handleStyle}) {


  return (
    <div className='p-1 pt-2' style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center'}}>
  <div className='w-100 text-left'>
  <label className='text-left text-primary'>Brush width</label>
  <div
  className='w-100 pl-2 pr-2'
  >
  <RangeSlider
className='w-100'
  value={drawingStyle.width}
  onChange={changeEvent => handleStyle('width', changeEvent.target.value)}
  tooltip="off"
  min={2}
  max={50}
  step={2}
/>
</div>
</div>
<br/>
<div className='w-100 text-left justify-center align-items-center'>
<label className='text-left text-primary'>Brush Color</label>
<div
className='w-100'
>
<BlockPicker 
triangle="hide"
width="100%"
color={drawingStyle.color}
onChange={(color, event) => handleStyle('color', color.hex)}
/>
</div>
</div>
    </div>
  )
}
