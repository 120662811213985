import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_PATIENT_DETAILS, OPEN_MODAL, SET_SEARCH_STRING } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



export default function ForgotPasswordModal({ }) {
    const dispatch = useDispatch()
    const { modal } = useSelector(({ ui }) => ui)

    const toggle = () => {
        if (modal === 'forgotPassword') {
            dispatch({ type: CLEAR_PATIENT_DETAILS })
            dispatch({ type: SET_SEARCH_STRING, payload: '' })
        }
        dispatch({ type: OPEN_MODAL, payload: modal === 'forgotPassword' ? null : 'forgotPassword' });
    }

    return (
        <Modal
            isOpen={modal === 'forgotPassword'}
            toggle={toggle}
        //    className={this.props.className}
        >
            <ModalHeader className='btnIri' >Forgot Password?</ModalHeader>
            <ModalBody>
                Please contact your administrator.
            </ModalBody>
            <ModalFooter>

                <Button color="secondary" onClick={toggle}>Close</Button>
            </ModalFooter>
        </Modal>
    )
}
