import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_PATIENT_DETAILS, OPEN_MODAL, SET_DATA, SET_SEARCH_STRING } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from "react-router-dom";



export default function NewPatientModal({ navigate }) {
    const dispatch = useDispatch()
    // const navigate = useNavigate();
    const { modal } = useSelector(({ ui }) => ui)



    const handleCreateNewPatient = (e) => {
        e.preventDefault();

        dispatch({ type: SET_DATA, payload: [] })
        dispatch({ type: SET_SEARCH_STRING, payload: '' })
        dispatch({ type: OPEN_MODAL, payload: null });
        navigate('/dashboard/patient/enterPatient')

    }



    const toggle = () => {
        if (modal === 'newPatient') {
            dispatch({ type: CLEAR_PATIENT_DETAILS })
            dispatch({ type: SET_SEARCH_STRING, payload: '' })
        }
        dispatch({ type: OPEN_MODAL, payload: modal === 'newPatient' ? null : 'newPatient' });
    }

    return (
        <Modal
            isOpen={modal === 'newPatient'}
        // toggle={toggle}
        //    className={this.props.className}
        >
            <ModalHeader className='btnIri'>Entering Patient Details</ModalHeader>
            <ModalBody>
                Do you want to enter a new patient's details?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="btnIri" onClick={handleCreateNewPatient}>Yes</Button>{' '}
                <Button color="secondary" onClick={toggle}>No</Button>
            </ModalFooter>
        </Modal>
    )
}
