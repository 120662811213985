// Importing helper modules
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import styles from 'css/styles.module.css';
import { useDispatch } from "react-redux";
import { uploadDocxLetter, uploadLetterTemplate } from "redux/actions/data.action";
import { fileUpload } from "redux/actions/file.action";
import { env_vars } from "Utils/config";


const LetterEditor = () => {
  // Editor state
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [urlContent, setUrlContent] = useState(`${env_vars.editor_api}/editor?fileExt=docx`);
  const [activeFile, setActiveFile] = useState('new.docx');
  const [rnd, setRnd] = useState(0);
  const fileInputRef = useRef(null);



  const handleButtonClick = () => {
    // Trigger file input click
    fileInputRef.current.click();
  };

  const handleLoad = (e) => {
    let file = e.target.files[0]
    setUrlContent('')

    if (file) {
      const formData = new FormData();
      formData.append('uploadedFile', file);


      const formData2 = new FormData();
      formData2.append('file', file);
      console.log(file)
      dispatch(uploadDocxLetter(formData))
        .then((response) => {
          let { filename } = response.data;
          console.log(filename, 'FILE NAME')
          dispatch(fileUpload(formData2, 2))
            .then(() => {
              // getItems(e.destinationDirectory)
            })
            .catch(err => {
              console.log(err)
            })
          console.log('File uploaded successfully:', response.data);
          //   handleGetTemplate(template)
          setActiveFile(filename)
          setUrlContent(`${env_vars.editor_api}/editor?fileName=${filename}&directUrl=true`)
          setRnd(Math.random())
          // Handle success if needed
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
          // Handle error if needed
        });
    } else {
      console.warn('No file selected for upload.');
      // Handle no file selected if needed
    }
  };


  const handleExit = () => {
    navigate(-1)
  }

  const handleSave = (val) => {
    // setUrlContent(null);
    setRnd(Math.random());
    console.log(activeFile, 'active file')

  }

  useEffect(() => {
    console.log(urlContent)

  }, [rnd])


  console.log(urlContent, 'CONT')

  return (
    <div className={styles.wrapper}>
      {urlContent &&
        <iframe
          title="Embedded Website"
          src={urlContent}
          width="85%"
          height="550px"
          maxHeight="100%"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      }
      <div className="d-flex w-100 justify-content-end pdf-actions">
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleLoad}
          style={{ display: 'none' }}
        />
        <Button className="btn btnIri mr-3" onClick={handleButtonClick}>
          Load
        </Button>
        <Button className="btn  mr-3" onClick={() => handleSave(urlContent)}>Save</Button>
        <Button className="btn btnIri mr-3" onClick={handleExit}>Exit</Button>
      </div>
    </div>
  );
};

export default LetterEditor;