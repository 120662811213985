import React, { useState } from 'react'
import Banner from '../../assets/logo_03.png'
import CustomModal from '../../component/Modals/CustomModal'
import { Button, FormFeedback, Input } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activateLicenseCode } from '../../redux/actions/auth.action';



export default function Licensing() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [license, setLicense] = useState(null);
    const [error, setError] = useState(null)

    const handleOkay = (e) => {
        e.preventDefault();
        setError(null)
        dispatch(activateLicenseCode(license))
            .then((res) => {
                console.log(res, 'licensing')
                localStorage.setItem('isLicensed', true)
                navigate('/setup')
            })
            .catch(err => {
                console.log(err.response.data, 'licensing')
                setError(err.response.data.error)
            })
    }


    return (
        <>
            <CustomModal
                open={true}
                title="Please enter COMPUSCOPE Colposcopy Machine License"
            >
                <form onSubmit={handleOkay}>
                    <span>Please contact Irisoft to provide you with a machine license for this product with the following details.</span>
                    <br /><br />
                    <div style={{ display: 'flex' }}>
                        <p className='col-md-3'>Please Quote</p><span className='col-md-3'> JF4818CC</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <p className='col-md-3'>License Code</p>
                        {/* <input className='form-control col-md-9' value={license} onChange={(e) => setLicense(e.target.value)} /> */}
                        <div className='col-md-9 pr-5'>

                            <Input
                                id="letterdate" name="referraldate"
                                className='form-control'
                                placeholder="Enter License Code"
                                value={license} onChange={(e) => setLicense(e.target.value)}
                                invalid={error}
                            />
                            <FormFeedback>
                                {error}
                            </FormFeedback>
                        </div>
                    </div>
                    <br /><br />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button className='btn btnIri' type="submit" style={{ width: '100px' }} >OK</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/* <Button className='btn' style={{ width: '100px' }}>CANCEL</Button> */}
                    </div>
                </form>
            </CustomModal>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center', height: '90vh' }}>
                <img src={Banner} height={200} width={400} />
            </div>
        </>
    )
}
