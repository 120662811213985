import axios from "axios";
// import authHeader from "./auth-header";
import { env_vars } from 'Utils/config';

const getData = () => {
  return axios.get(env_vars.editor_api + "/data")
    .catch(err => {
      console.log(err)
    });
};



const uploadFile = (data) => {
  return axios.post(env_vars.editor_api + `/upload`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }).catch(err => {
    console.log(err)
  });
}


const downloadFile = (fileName) => {
  // http://localhost:3500/download?fileName=GP%20First%20DNA%20Letter.docx&useraddress=__1
  return axios.get(env_vars.editor_api + `/download?fileName=${fileName}`).catch(err => {
    console.log(err)
  });
}

const deleteFile = (fileName) => {
  // http://localhost:3500/download?fileName=GP%20First%20DNA%20Letter.docx&useraddress=__1
  return axios.delete(env_vars.editor_api + `/file?filename=${fileName}`).catch(err => {
    console.log(err)
  });
}



export default {
  getData,
  uploadFile,
  downloadFile,
  deleteFile
};