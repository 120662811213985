import React from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";

// import Sonnet from '../../components/Sonnet';
import "../../../css/pages/tab-page.css";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    PieChart, Pie, Sector, Cell,
    ResponsiveContainer
} from "recharts";

const data = [
    {
        date: "2022-01",
        uv: 4000,
        pv: 2400,
        amt: 2400,
        legend1: 30,
        legend2: 5,
        legend3: 50
    },
    {
        date: "2022-02",
        uv: 3000,
        pv: 1398,
        amt: 2210,
        legend1: 40,
        legend2: 11,
        legend3: 80
    },
    {
        date: "2022-03",
        uv: 2000,
        pv: 9800,
        amt: 2290,
        legend1: 60,
        legend2: 23,
        legend3: 60
    },
    {
        date: "2022-04",
        uv: 2780,
        pv: 3908,
        amt: 2000,
        legend1: 20,
        legend2: 2,
        legend3: 26
    },
    {
        date: "2022-05",
        uv: 1890,
        pv: 4800,
        amt: 2181,
        legend1: 58,
        legend2: 33,
        legend3: 80
    },
    {
        date: "2022-06",
        uv: 2390,
        pv: 3800,
        amt: 2500,
        legend1: 62,
        legend2: 10,
        legend3: 89
    },
    {
        date: "2022-07",
        uv: 3490,
        pv: 4300,
        amt: 2100,
        legend1: 24,
        legend2: 13,
        legend3: 40
    },
    {
        date: "2022-08",
        uv: 4000,
        pv: 2400,
        amt: 2400,
        legend1: 72,
        legend2: 34,
        legend3: 80
    },
    {
        date: "2022-09",
        uv: 3000,
        pv: 1398,
        amt: 2210,
        legend1: 18,
        legend2: 1,
        legend3: 30
    },
    {
        date: "2022-10",
        uv: 2000,
        pv: 9800,
        amt: 2290,
        legend1: 10,
        legend2: 2
    },
    {
        date: "2022-11",
        uv: 2780,
        pv: 3908,
        amt: 2000,
        legend1: 79,
        legend2: 33,
        legend3: 120

    },
    {
        date: "2022-12",
        uv: 1890,
        pv: 4800,
        amt: 2181,
        legend1: 101,
        legend2: 34,
        legend3: 120
    },
];

const data2 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};

const monthTickFormatter = (tick) => {
    const date = new Date(tick);

    return date.getMonth() + 1;
};

const renderQuarterTick = (tickProps) => {
    const { x, y, payload } = tickProps;
    const { value, offset } = payload;
    const date = new Date(value);
    const month = date.getMonth();
    const quarterNo = Math.floor(month / 3) + 1;

    if (month % 3 === 1) {
        return (
            <text x={x} y={y - 4} textAnchor="middle">{`Q${quarterNo}`}</text>
        );
    }

    const isLast = month === 11;

    if (month % 3 === 0 || isLast) {
        const pathX = Math.floor(isLast ? x + offset : x - offset) + 0.5;

        return <path d={`M${pathX},${y - 4}v${-35}`} stroke="red" />;
    }
    return null;
};

const Automatic = () => {
    return (
        <>
            <div className="card-header bg-iri txtLeft">
                <h4 className="m-b-0 text-white">Automatic</h4>
                <div className="clr"></div>
            </div>
            <div className="row  txtLeft">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body vtabs">

                            <h3 className="m-b-0 ">KC65 Options</h3>
                            <br />
                            <br />
                            <div style={{ width: '100%' }}>
                                <ResponsiveContainer>
                                    <Tab.Container
                                        id="left-tabs-example"
                                        defaultActiveKey="first"
                                    >
                                        <Row>
                                            <Col sm={2}>
                                                <Nav
                                                    variant="pills"
                                                    className="flex-column nav nav-tabs tabs-vertical"
                                                >
                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="first"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KC65-A
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="second"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KC65-B
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="third"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KC65-C Part 1
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="fourth"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KC65-C Part 2
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="fifth"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KC65-D
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="sixth"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KC65-E
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="kpidata"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                KPI Data
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="nav-item">
                                                        <Nav.Link
                                                            eventKey="seventh"
                                                            className="nav-link"
                                                        >
                                                            <span className="hidden-xs-down">
                                                                Cover
                                                            </span>{" "}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col sm={10}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <div className="">
                                                            <h3>KC65-A</h3>
                                                            <h4>
                                                                Referral - First appointment
                                                                waiting times & results
                                                            </h4>
                                                            <br /><br />
                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="pv"
                                                                    fill="#8884d8"
                                                                />
                                                                <Bar
                                                                    dataKey="uv"
                                                                    fill="#82ca9d"
                                                                />
                                                            </BarChart>
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second">
                                                        <div className="">
                                                            <h3>KC65-B</h3>
                                                            <h4>
                                                                Appointment for Colposcopy
                                                            </h4>
                                                            <br /><br />
                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="legend1"
                                                                    fill="#c46977"
                                                                    name="New"
                                                                />
                                                                <Bar
                                                                    dataKey="legend2"
                                                                    fill="#93c47d"
                                                                    name="Return for Treatment"
                                                                />
                                                                <Bar
                                                                    dataKey="legend3"
                                                                    fill="#2986cc"
                                                                    name="Follow Up"
                                                                />
                                                            </BarChart>
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="third">
                                                        <div className="">
                                                            <h3>KC65-C Part 1</h3>
                                                            <h4>
                                                                First attend referral result & procedure
                                                            </h4>

                                                            <br /><br />

                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data2}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />

                                                                <Bar
                                                                    dataKey="legend2"
                                                                    fill="#fb9678"
                                                                />
                                                            </BarChart>
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fourth">
                                                        <div className="">
                                                            <h3>KC65-C Part 2</h3>
                                                            <h4>
                                                                All Attendance Procedure Type and Referral
                                                            </h4>
                                                            <br /><br />
                                                            <BarChart
                                                                width={800}
                                                                height={400}
                                                                data={data}
                                                                margin={{
                                                                    top: 5,
                                                                    right: 30,
                                                                    left: 20,
                                                                    bottom: 5,
                                                                }}
                                                            >
                                                                <CartesianGrid strokeDasharray="3 3" />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    tickFormatter={
                                                                        monthTickFormatter
                                                                    }
                                                                />
                                                                <XAxis
                                                                    dataKey="date"
                                                                    axisLine={false}
                                                                    tickLine={false}
                                                                    interval={0}
                                                                    tick={renderQuarterTick}
                                                                    height={1}
                                                                    scale="band"
                                                                    xAxisId="quarter"
                                                                />
                                                                <YAxis />
                                                                <Tooltip />
                                                                <Legend />
                                                                <Bar
                                                                    dataKey="legend1"
                                                                    fill="#c46977"
                                                                    name="01 Jan 21 - 31 Dec 22"
                                                                />

                                                            </BarChart>
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="fifth">
                                                        <div className="">
                                                            <h3>KC65-D</h3>
                                                            <h4>
                                                                Biopsy result waiting times
                                                            </h4>
                                                            <br /><br />

                                                            <PieChart width={700} height={400}>
                                                                <Pie
                                                                    data={data2}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={180}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>

                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="sixth">
                                                        <div className="">
                                                            <h3>KC65-E</h3>
                                                            <h4>
                                                                Biopsy types and outcomes
                                                            </h4>
                                                            <br /><br />
                                                            <PieChart width={700} height={400}>
                                                                <Pie
                                                                    data={data2}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={180}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="sixthS">
                                                        <div className="">
                                                            <h3>KPI Data</h3>
                                                            <h4>
                                                                KPI Data
                                                            </h4>
                                                            <br /><br />
                                                            <PieChart width={700} height={400}>
                                                                <Pie
                                                                    data={data2}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    labelLine={false}
                                                                    label={renderCustomizedLabel}
                                                                    outerRadius={180}
                                                                    fill="#8884d8"
                                                                    dataKey="value"
                                                                >
                                                                    {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>
                                                        </div>
                                                    </Tab.Pane>

                                                    <Tab.Pane eventKey="seventh">
                                                        <div className="">
                                                            <h3>KC65 Cover</h3>
                                                            <h4>
                                                                ICSP Cover
                                                            </h4>
                                                            <br /><br />
                                                            <div className="card-body">
                                                                <h4 className="card-title" id="1">1. Title will be here</h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <h4 className="card-title m-t-40" id="22">2. Title will be here</h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <h4 className="card-title m-t-40" id="3">3. Title will be here</h4>
                                                                <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi.</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                                <p> enean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum. Nam quam nunc, blandit vel, luctus pulvinar,</p>
                                                            </div>
                                                        </div>
                                                    </Tab.Pane>

                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Automatic;
