import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_PATIENT_DETAILS, OPEN_MODAL, SET_SEARCH_STRING } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



export default function NoAppointmentModal({ }) {
    const dispatch = useDispatch()
    const { modal } = useSelector(({ ui }) => ui)

    const toggle = () => {

        dispatch({ type: OPEN_MODAL, payload: null});
    }

    return (
        <Modal
            isOpen={modal === 'noAppointmentModal'}
            toggle={toggle}
        //    className={this.props.className}
        >
            <ModalHeader className='btnIri' >Entering Patient Details</ModalHeader>
            <ModalBody>
                <span>Error: There ar no referral/appointment details for this patient</span>
                <br />                <br />

                <span>You should select the 'Personal Details' option and then enter the referral and first appointment dates, and the referrer's name and address.
                Please contact your administrator.</span>
            </ModalBody>
            <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                    < Button onClick={ toggle } className='btn'> OK</Button>
            </ModalFooter >
        </Modal >
    )
}
