import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CLEAR_PATIENT_DETAILS, OPEN_MODAL, SET_SEARCH_STRING } from '../../redux/actions/types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';



export default function CustomModal(props) {
    const dispatch = useDispatch()
    const { title, open, onClose, onOkay, onNo, onYes, size, onCancel, } = props;

    const handleOkay = () => {
        onOkay && onOkay()
        onYes && onYes()
    }

    const toggle = () => {
        onClose && onClose()
        onNo && onNo()
        onCancel && onCancel()
        dispatch({ type: OPEN_MODAL, payload: null });
    }

    return (
        <Modal
            isOpen={open}
            size={size}
            toggle={onClose && toggle}
        //    className={this.props.className}
        >
            <ModalHeader className='btnIri' >{title}</ModalHeader>
            <ModalBody>
                <div style={{ width: "100%" }}>
                    {props.children}
                </div>
            </ModalBody>
            {(onCancel || onOkay || onYes || onNo) &&
                <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                    {onOkay && < Button onClick={() => handleOkay()} className='btn btnIri' style={{ width: '75px' }}>OK</Button>}
                    {onYes && < Button onClick={() => handleOkay()} className='btn btnIri' style={{ width: '75px' }}>Yes</Button>}
                    {onNo && < Button onClick={toggle} className='color-black' style={{ width: '75px' }}>No</Button>}
                    {onCancel && < Button onClick={toggle} className='color-black' style={{ width: '75px' }}>Cancel</Button>}
                </ModalFooter >}
        </Modal >
    )
}
