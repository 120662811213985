import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ResultList from '../../../component/TraceResultList';
import { CLEAR_DATA, CLEAR_SORT, OPEN_MODAL, SET_DATA, SET_SEARCH_STRING, SET_VIEW_OPTION } from '../../../redux/actions/types';
import { searchPatientDetails } from '../../../redux/actions/data.action';

const TracePatient = () => {
  const { data } = useSelector(({ data }) => data)
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  const handleChanges = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }


  const handleSearch = async (e) => {
    e.preventDefault();

    dispatch(searchPatientDetails({ searchString: values.Surname + ' ' + values.DateOfBirth, ...values }))
      .then(res => {
        if (res && res.length !== 0) {
        }
        /* else {
          dispatch({ type: SET_VIEW_OPTION, payload: 'enter' })
          dispatch({ type: OPEN_MODAL, payload: 'newPatient' });
        } */
        dispatch({ type: CLEAR_SORT })
      })
  }


  useEffect(() => {
    return () => {
      dispatch({ type: CLEAR_DATA })
    }
  }, [])


  return (<>
    <div className="card-header bg-iri txtLeft">
      <h4 className="m-b-0 text-white">Trace Patient</h4>
    </div>
    <div className="card-body"
      style={{ backgroundColor: '#E0E0E0', display: 'flex', flexDirection: 'column' }}
    >
      <form className="d-flex w-100"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        onSubmit={handleSearch}>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <div className='d-flex flex-grow-1 text-left'>
            <div className='d-flex text-left w-50'>
              <label className='text-dark text-15 mr-3 text-right mt-1'>Surname</label>
              <input type="text" style={{
                // height: 50,
                // width: '200px',

              }} className="form-control w-50 border-primary border-3 col-md-8" placeholder="Enter Patient Surname" value={values.Surname} onChange={handleChanges('Surname')}
              />
            </div>
            <div className='d-flex text-left w-50 flex-grow-1'>
              <label className='text-dark text-15 mr-2 text-right col-md-4 mt-1'>Date of Birth</label>
              <input type="date"
                style={{
                  width: '200px',
                }} className="form-control w-50 border-primary border-3 col-md-8" placeholder=""
                value={values.DateOfBirth} onChange={handleChanges('DateOfBirth')}
              />
            </div>
          </div>
          <div className='flex-grow-1 text-right'>
            <button className="btn btnIri w-20 "
              disabled={(!values.Surname && !values.DateOfBirth)}
              type='submit'
            >Search</button>
          </div>

        </div>

      </form>
      <ResultList list={data} values={values} />
    </div>



  </>)
}

export default TracePatient;
