import React from 'react'

export default function OptionsFrameHOC(props) {

  let { title, titleSize, titleClass, containerClass } = props;
  return (
    <div className='pt-2' style={{ height: '100%', width: "100%", border: "3px solid #ccc", alignItems: "center", justifyContent: "flex-start", display: "flex", flexDirection: "column" }}>
      {
        titleSize == "lg" ? <h5 className={` card-title text-center text-primary pr-1 pl-1 ${titleClass}`} style={{ marginTop: "-20px", backgroundColor: "#FFF" }}>{props.title}</h5>
          : titleSize == "md" ? <h6 className={`text-success text-left text-primary pr-1 pl-1 ${titleClass}`} style={{ marginTop: "-20px", backgroundColor: "#FFF" }}>{props.title}</h6>
            : <h6 className={`text-center text-primary pr-1 pl-1 ${titleClass}`} style={{ marginTop: "-20px", fontSize: "15px", backgroundColor: "#FFF", fontWeight: '500' }}>{title}</h6>
      }


      <div style={{ width: "100%" }} className={containerClass}>
        {props.children}
      </div>
    </div >
  )
}
