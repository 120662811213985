import React , { useState } from 'react'
import StepWizard from "react-step-wizard";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";

const ActionButtons = (props) => {
  const handleBack = () => {
      props.previousStep();
  };

  const handleNext = () => {
      props.nextStep();
  };

  const handleFinish = () => {
      props.lastStep();
        
  };

  return (
      <div>
          <Row>
              {props.currentStep > 1 && (
                  <Col>
                      <Button className="btn btnIri marRight20" onClick={handleBack}>Back</Button>
                  </Col>
              )}
              <Col>
                  {props.currentStep < props.totalSteps && (
                      <Button className="btn btnIri marRight20" onClick={handleNext}>Next</Button>
                  )}
                  {props.currentStep === props.totalSteps && (
                      <Button className="btn btnIri marRight20 fR" onClick={handleFinish}>Finish</Button>
                  )}
              </Col>
          </Row>
      </div>
  );
};

const One = (props) => {
  const [info1, setInfo1] = useState({});
  const [error, setError] = useState("");

    const onInputChanged = (event) => {
        const targetName = event.target.name;
        const targetValue = event.target.value;

        setInfo1((info1) => ({
            ...info1,
            [targetName]: targetValue,
        }));
    };

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
       
        const form = event.currentTarget;
        console.log('handleSubmit', event);
        console.log('handleSubmit form', form);
        console.log('handleSubmit form checkValidity', form.checkValidity());
      
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            setError("");
            props.nextStep();
            props.userCallback(info1);
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };
    return (
    <>
         <div className="card-body">
        
         <Form
         className='txtLeft'
        id="formValid"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
         > 

          <div className="text-xs-center fL">
              <button type="submit" className="btn btnIri marRight20" disabled>CYTOLOGY FORM</button>
              <Button type="submit" className="btn btnIri marRight20">NEXT</Button>
              <button type="reset" className="btn btnIri marRight20 fR">EXIT</button>
          </div>
          <div className='clearfix'></div>
          <div className="form-body">
            <div className="row p-t-20">
              
              <div className="col-md-6 padRight50">
                <br /><br /><br />
                <h3 className="card-title txtLeft">Cervical Histology</h3>
                <hr />

                <label className='fL col-md-12'><b>NeoPlastic</b></label>
                <div className='clr'></div>
                <div className='row  p-t-20'>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className='col-md-12'>
                  <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false" />
                  </div>
                </div>
              </div>

             
              <div className="col-md-6 padRight50">
                <button className='btn btn waves-effect waves-light btn-warning fR' >waiting</button>
                <div className="clr"></div>
                <br />
                <div className="form-group row">
                  <label className="control-label col-md-6">Patient Notification Date/Time <span className="text-danger">*</span></label>
                  <input type="text" id="firstName" className="form-control col-md-6" placeholder="" required />
                </div>

                <label className='fL col-md-12'><b>Non - NeoPlastic</b></label>
                <div className='clr'></div>
                <div className='row  p-t-20'>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className='col-md-12'>
                  <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false" />
                  </div>

                </div>
              </div>

            
              <div className="col-md-6 padRight50">
                <br /><br /><br />
                <h3 className="card-title txtLeft">Vaginal Histology</h3>
                <hr />

                <label className='fL col-md-12'><b>NeoPlastic</b></label>
                <div className='clr'></div>
                <div className='row  p-t-20'>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className='col-md-12'>
                  <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false" />
                  </div>
                </div>
              </div>

              <div className="col-md-6 padRight50">
                <br /><br /><br /><br /><br /><br />
                <label className='fL col-md-12'><b>NeoPlastic</b></label>
                <div className='clr'></div>
                <div className='row  p-t-20'>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>

                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                    <div className=" custom-radio">
                      <input type="checkbox" value="1" name="styled_radio" required="" className="marRight20"   />
                      <label className="" > GP</label>
                      <div className='clearfix'></div><br />

                    </div>
                  </div>
                  <div className='col-md-12'>
                  <textarea name="textarea" id="textarea" className="form-control" required="" placeholder="" aria-invalid="false" />
                  </div>
                </div>
              </div>

              <div className='clr'></div>
            
              <div className="col-md-6 padRight50">
              <br /><br />
                <div className="form-group">
                  <label className="control-label">Keyed in by</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

                <div className="form-group">
                  <label className="control-label">Date / Time</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

                <div className="form-group">
                  <label className="control-label">Laboratory Name</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

                <div className="form-group">
                  <label className="control-label">Laboratory No</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

              </div>

              <div className="col-md-6 padRight50">
              <br /><br />
                <div className="form-group">
                  <label className="control-label">Keyed in by</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

                <div className="form-group">
                  <label className="control-label">Date / Time</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

                <div className="form-group">
                  <label className="control-label">Laboratory Name</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

                <div className="form-group">
                  <label className="control-label">Laboratory No</label>
                  <input type="text" id="firstName" className="form-control" placeholder="" />
                </div>

              </div>
             

            </div>
          </div>

          <div className="text-xs-center fL">
              <button type="submit" className="btn btnIri marRight20" disabled>CYTOLOGY FORM</button>
              <Button type="submit" className="btn btnIri marRight20">NEXT</Button>
              <button type="reset" className="btn btnIri marRight20 fR">EXIT</button>
          </div>
          <div className='clearfix'></div>
          {/* <button className='btn btn waves-effect waves-light fR' >Submit</button> */}
        </Form>

      </div>
            </>
    );

   
}

const Two = (props) => {
  const [info2, setInfo2] = useState({});
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);

  const onInputChanged = (event) => {
      const targetName = event.target.name;
      const targetValue = event.target.value;

      setInfo2((info2) => ({
          ...info2,
          [targetName]: targetValue,
      }));
  };

  const handleSubmit = (event) => {
      console.log('handle sumbit 2')
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();


      }else{
          console.log("SHOW SUCCESS VALIDATION SUBMIT")
          setError("");
          props.nextStep();
          props.userCallback(info2);
          event.preventDefault();
          event.stopPropagation();
        
      }

      setValidated(true);
  };


  const handleBack = () => {
      props.previousStep();
  };

  return (
      <div>
          <div className="card-title txtLeft">
              <label className="card-title txtLeft">Patient Management Plan</label>
          </div>
          <div className="card-body txtLeft">
          {props.user.ReferralDate || ""}
          {props.user.DateTime || ""}
          {props.user.DateReceived|| ""}
          {props.user.Clinic|| ""}
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="text-xs-center fL txtLeft">
                  <Button className="btn btnIri marRight20" onClick={handleBack}>PREV</Button>
                  <Button type="submit" className="btn btnIri marRight20">NEXT</Button>
              </div>
              <div className="clearfix"></div>
              <br/>
                  <Row className="mb-3">
                      <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom01"
                      >
                          <Form.Label>First name</Form.Label>
                          <Form.Control
                              required
                              type="text"
                              placeholder="First name"
                              defaultValue="Mark"
                          />
                          <Form.Control.Feedback>
                              Looks good!
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustom02"
                      >
                          <Form.Label>Last name</Form.Label>
                          <Form.Control
                              required
                              type="text"
                              placeholder="Last name"
                              defaultValue="Otto"
                          />
                          <Form.Control.Feedback>
                              Looks good!
                          </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                          as={Col}
                          md="4"
                          controlId="validationCustomUsername"
                      >
                          <Form.Label>Username</Form.Label>
                          <InputGroup hasValidation>
                              <InputGroup.Text id="inputGroupPrepend">
                                  @
                              </InputGroup.Text>
                              <Form.Control
                                  type="text"
                                  placeholder="Username"
                                  aria-describedby="inputGroupPrepend"
                                  required
                                  name="Username"
                                  onChange={onInputChanged}
                                  
                              />
                              <Form.Control.Feedback type="invalid">
                                  Please choose a username.
                              </Form.Control.Feedback>
                          </InputGroup>
                      </Form.Group>
                  </Row>
              <div className="clearfix"></div>
              <br/>
              <div className="text-xs-center fL">
                  <Button className="btn btnIri marRight20" onClick={handleBack}>PREV</Button>
                  <Button type="submit" className="btn btnIri marRight20">NEXT</Button>
              </div>
              </Form>
          </div>
          
      </div>
  );
};

const Three = (props) => {


  const handleLastStep = () => {
      props.lastStep();
      // props.completeCallback();
      console.log('call API and submit all data', props.user)


        window.location.reload(false)
  };

  return (
      <div>
          <div className="text-xs-center fL">
              <ActionButtons {...props} lastStep={handleLastStep} />
          </div>
          <div className="clearfix"></div>
          <br />
              <p>Referral Date: {props.user.ReferralDate || ""}</p>
              <p>DateT ime: {props.user.DateTime || ""}</p>
              <p>Date Received: {props.user.DateReceived || ""}</p>
              <p>Clinic: {props.user.Clinic || ""}</p>
              <p>Username: {props.user.Username || ""}</p>
          <div className="clearfix"></div>
          <br />
          <div className="text-xs-center fL">
              <ActionButtons {...props} lastStep={handleLastStep} />
          </div>
      </div>
  );
};


const ExportBiopsy = () => {

  const [stepWizard, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);

  const assignStepWizard = (instance) => {
      setStepWizard(instance);
  };

  const assignUser = (val) => {
      console.log("parent receive user callback");
      console.log(val);
      setUser((user) => ({
          ...user,
          ...val,
      }));
  };

  const handleStepChange = (e) => {
      console.log("step change");
      console.log(e);
      setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
      alert("You r done. TQ");

  };

  return (
    <>
      <div className="card-header bg-iri txtLeft">
        <h4 className="m-b-0 text-white">Export Biopsy</h4>
      </div>
      

<div className="card-body">
        
        {/* NOTE: IMPORTANT !! StepWizard must contains at least 2 children components, else got error */}
        <StepWizard
            instance={assignStepWizard}
            onStepChange={handleStepChange}
        >
            <One userCallback={assignUser} />
            <Two user={user} userCallback={assignUser} />
            <Three user={user} completeCallback={handleComplete} />
        </StepWizard>
        </div>
    </>)
}

export default ExportBiopsy;
