import { SET_FILES } from "../actions/types";

const INIT_STATE = {
   files: []
};

export default (state = INIT_STATE, action) => {
  const {type, payload} = action;

  switch (type) {
    case SET_FILES: {
      return {
        ...state,
        files: payload
      };
    }
    
    default:
        return state;
};
}
